import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { validateAccessCode } from '../api/lawChatApi';

const AccessCodePage = () => {
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const hasValidatedRef = useRef(false);

    const mutation = useMutation(validateAccessCode, {
        onSuccess: (data) => {
            // Store both the access code and the signature in localStorage.
            localStorage.setItem('accessCode', code);
            localStorage.setItem('accessSignature', data.signature);
            navigate('/home');
        },
        onError: () => {
            alert('Invalid access code.');
        },
    });

    useEffect(() => {
        const storedCode = localStorage.getItem('accessCode');
        const storedSignature = localStorage.getItem('accessSignature');
        if (storedCode && storedSignature && !hasValidatedRef.current) {
            setCode(storedCode);
            hasValidatedRef.current = true;
            mutation.mutate(storedCode, {
                onSuccess: () => {
                    navigate('/home');
                },
                onError: () => {
                    localStorage.removeItem('accessCode');
                    localStorage.removeItem('accessSignature');
                },
            });
        }
    }, [mutation, navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!code.trim()) {
            alert('Please enter an access code.');
            return;
        }
        mutation.mutate(code);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-8">
                <h1 className="text-4xl font-extrabold text-black mb-6 text-center tracking-wide">
                    Lexcha
                </h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <input
                            id="accessCode"
                            type="text"
                            placeholder="Enter your access code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                    >
                        Validate
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AccessCodePage;