import axios from 'axios';
import { getIdToken } from '../services/authService';

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(async (config) => {
    const token = await getIdToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => Promise.reject(error));

export const signin = async () => {
    const response = await api.post('/signin');
    return response.data;
};

export const getUser = async () => {
    const response = await api.get('/user');
    return response.data;
};

export const getTopics = async () => {
    const response = await api.get('/topics');
    return response.data;
};

export const getModes = async () => {
    const response = await api.get('/modes');
    return response.data;
};

export const getMessages = async (topicId) => {
    const response = await api.get(`/topics/${topicId}/messages`);
    return response.data;
};

export const postMessage = async (topicId, message, modeId) => {
    const response = await api.post(`/topics/${topicId}/messages`, message, {
        params: {
            mode_id: modeId,
        },
    });
    return response.data;
};

export const deleteMessages = async (topicId) => {
    await api.delete(`/topics/${topicId}/messages`);
};

export const postEvent = async (event) => {
    const response = await api.post('/events', event);
    return response.data;
};

export const validateAccessCode = async (code) => {
    const response = await api.post('/access-codes/validate', { code });
    return response.data;
  };