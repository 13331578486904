import { useQuery } from 'react-query';
import { getTopics } from '../api/lawChatApi';
import { slugify } from '../utils/slugify';

export const useTopics = () => {
  return useQuery('topics', async () => {
    const topics = await getTopics();

    // Object to track slug occurrences for uniqueness
    const slugCount = {};

    // Generate slugs and ensure uniqueness
    const topicsWithSlugs = topics.map((topic) => {
      let baseSlug = slugify(topic.name);
      if (!baseSlug) {
        baseSlug = encodeURIComponent(topic.name);
      }

      // Check if the slug already exists
      if (slugCount[baseSlug]) {
        // If it exists, append the topic ID to make it unique
        baseSlug = `${baseSlug}-${topic.id}`;
      }

      // Mark this slug as used
      slugCount[baseSlug] = true;

      return {
        ...topic,
        slug: baseSlug,
      };
    });

    return topicsWithSlugs;
  });
};