import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Your public key in PEM format.
const PUBLIC_KEY_PEM = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApkm/gGGKXhSURl+Dq808
lzjZvsrc4dVQWMZ92ld6I1hmOZth9xQ/pvfnB3qi/vz5hrjNuVxwIh9LtFBV+fFZ
wncaciuzZYWkw1DmYggNCvyNaw6YHlkSSzO1fncAxpWpEbfn3C2IhBsPRWFopUJk
Q5KBL7uKBSnRGUf8gXeVCcDALUrYIO0oF4iPzGh3ihSbYlwgx448sMA/GkvGO5Te
T4J28b4Uk2eyAYF3Z6OtZUqDk14DFtWU/wyMQdsEtBVYYBC9h7/ae6HK5y/mFlas
puQSOVWlHSat8pFTD1ByyYWl7u/n1tSVzrfIFdcGlD2w+dC4JUlTI/lwg5M23eUI
vwIDAQAB
-----END PUBLIC KEY-----`;

// Utility: Convert a base64 string to an ArrayBuffer.
const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

// Utility: Convert a string to an ArrayBuffer.
const str2ab = (str) => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0; i < str.length; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
};

// Verify the signature for the given data using the provided public key.
const verifySignature = async (publicKeyPem, data, signatureB64) => {
    // Remove PEM header and footer and line breaks
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    const pemContents = publicKeyPem
        .replace(pemHeader, "")
        .replace(pemFooter, "")
        .replace(/\n/g, "");
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);

    // Import the public key for verification
    const cryptoKey = await window.crypto.subtle.importKey(
        "spki",
        binaryDer,
        {
            name: "RSASSA-PKCS1-v1_5",
            hash: { name: "SHA-256" },
        },
        false,
        ["verify"]
    );

    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const signatureBuffer = base64ToArrayBuffer(signatureB64);

    // Verify the signature
    const verified = await window.crypto.subtle.verify(
        {
            name: "RSASSA-PKCS1-v1_5",
        },
        cryptoKey,
        signatureBuffer,
        dataBuffer
    );
    return verified;
};

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const storedCode = localStorage.getItem('accessCode');
    const storedSignature = localStorage.getItem('accessSignature');

    useEffect(() => {
        const verifyLocalSignature = async () => {
            if (storedCode && storedSignature) {
                try {
                    const isValid = await verifySignature(PUBLIC_KEY_PEM, storedCode, storedSignature);
                    if (!isValid) {
                        localStorage.removeItem('accessCode');
                        localStorage.removeItem('accessSignature');
                        navigate('/');
                    }
                } catch (error) {
                    console.error("Error verifying signature:", error);
                    localStorage.removeItem('accessCode');
                    localStorage.removeItem('accessSignature');
                    navigate('/');
                }
            } else {
                navigate('/');
            }
        };

        verifyLocalSignature();
    }, [storedCode, storedSignature, navigate]);

    return children;
};

export default ProtectedRoute;