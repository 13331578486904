import React, { useState } from 'react';
import { FaClipboard } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { usePostEvent } from '../hooks/usePostEvent';

const ProfileModal = ({ user, googleUser, onClose, expandCredits }) => {
    const [isExpanded, setIsExpanded] = useState(expandCredits);
    const { mutate: postEventMutation } = usePostEvent();

    const copyAccountNumber = () => {
        const accountNumber = "91391001287204";
        navigator.clipboard.writeText(accountNumber);
        toast.success("Account number copied to clipboard");
        const event = {
            type: "copy_account_number",
        };
        postEventMutation(event);
    };

    const copyMemoCode = () => {
        const memoCode = `lexcha${user.id}`;
        navigator.clipboard.writeText(memoCode);
        toast.success("Memo code copied to clipboard");
        const event = {
            type: "copy_memo_code",
        };
        postEventMutation(event);
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 p-4">
            <div className="bg-white w-full max-w-lg rounded-lg shadow-lg relative flex flex-col" style={{ maxHeight: '80vh' }}>
                {/* Close Button */}
                <button
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-2xl focus:outline-none"
                    onClick={onClose}
                    aria-label="Close modal"
                >
                    &times;
                </button>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-6 pt-8">
                    {/* Modal Title */}
                    <h2 className="text-2xl font-bold mb-4 text-center">Profile</h2>

                    {/* Profile Details */}
                    <div className="mb-6 space-y-2">
                        <p><strong>Name:</strong> {user.full_name}</p>
                        <p><strong>Email:</strong> {googleUser.email}</p>
                        <p><strong>Available Credits:</strong> {user.credits}</p>
                    </div>

                    {/* Expandable "Get More Credits" Section */}
                    {/* <div>
                        <button
                            className="w-full text-left flex justify-between items-center p-3 bg-blue-100 rounded-md hover:bg-blue-200 focus:outline-none"
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            <span>Get More Credits</span>
                            <span>{isExpanded ? '−' : '+'}</span>
                        </button>

                        {isExpanded && (
                            <div className="mt-4 p-4 bg-gray-100 rounded-md space-y-4">
                                <p className="mb-2">
                                    <strong>1 Credit = 100 KRW.</strong>
                                </p>
                                <p className="mb-2">
                                    We currently accept direct bank transfers only. Please note that there may be delays in crediting your account due to processing times with this payment method.
                                </p>
                                <p className="mb-2">
                                    Please transfer the desired amount to the account below and include the memo code shown below in the memo field to receive credits.
                                </p>
                                <div>
                                    <p className="font-semibold">Bank Account Details:</p>
                                    <div className="mb-2 flex items-center flex-wrap">
                                        <p className="mr-2">HanaBank</p>
                                        <p className="mr-2 font-semibold">913-910012-87204</p>
                                        <button onClick={copyAccountNumber} className="text-gray-500 hover:text-gray-700">
                                            <FaClipboard size={16} />
                                        </button>
                                    </div>
                                    <p className="mb-2">Account Holder: 주식회사 소프트블록스</p>
                                    <div className="flex items-center">
                                        <p className="mr-2">
                                            <strong>Memo Code:</strong> lexcha{user.id}
                                        </p>
                                        <button onClick={copyMemoCode} className="text-gray-500 hover:text-gray-700">
                                            <FaClipboard size={16} />
                                        </button>
                                    </div>
                                </div>
                                <p className="text-sm text-red-500">
                                    Warning: Amounts transferred without the memo code will be refunded.
                                </p>
                                <p className="text-sm text-gray-500">
                                    Thank you for your understanding.
                                </p>
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ProfileModal;
