import Fuse from 'fuse.js';

export async function pdfSearch({
    pdfDocument,
    outline,
    combinedSearchTerm,
    primarySearchTerm,
    fallbackSearchTerm,
    language
}) {
    let candidatePages = [];

    //-----------------------------------------------
    // 1) Attempt to find relevant pages via outline
    //-----------------------------------------------
    if (outline.length > 0) {
        // We'll do a two-pass approach for combinedSearchTerm in the outline:
        // (a) exact or near-exact, (b) fuzzy fallback
        const outlineSearcher = (term, thresholdVal) => {
            const fuse = new Fuse(outline, {
                keys: ['title'],
                threshold: thresholdVal,
                ignoreLocation: true,
                minMatchCharLength: language === 'KO' ? 3 : 7,
                includeMatches: true,
            });
            return fuse.search(term);
        };

        const tryOutlineMatch = (term) => {
            // Pass 1: near-exact threshold = 0.2
            let results = outlineSearcher(term, 0.2);
            // If nothing found, pass 2: looser threshold = 0.4
            if (results.length === 0) {
                results = outlineSearcher(term, 0.4);
            }
            return results;
        };

        // 1A) Try combinedSearchTerm
        let outlineResults = combinedSearchTerm ? tryOutlineMatch(combinedSearchTerm) : [];
        // Check if there's anything starting with Article number
        outlineResults = outlineResults.filter((result) => result.item?.title?.startsWith(fallbackSearchTerm));
        // console.log("Combined search result")
        // console.log(outlineResults)

        // 1B) If still empty, try primary / fallback (whichever is available)
        if (outlineResults.length === 0 && primarySearchTerm) {
            outlineResults = tryOutlineMatch(primarySearchTerm);
            // console.log("Primary search result")
            // console.log(outlineResults)
            outlineResults = outlineResults.filter((result) => result.item?.title?.startsWith(fallbackSearchTerm) || result.item?.title?.includes(primarySearchTerm));
            // console.log(outlineResults)
        }
        if (outlineResults.length === 0 && fallbackSearchTerm) {
            outlineResults = tryOutlineMatch(fallbackSearchTerm);
            outlineResults = outlineResults.filter((result) => result.item?.title?.startsWith(fallbackSearchTerm));
            // console.log("Fallback search result")
            // console.log(outlineResults)
        }

        // If we do end up with results, gather nearby pages
        if (outlineResults && outlineResults.length > 0) {
            const pagesSet = new Set();
            for (const { item } of outlineResults) {
                const { dest } = item;
                if (dest && Array.isArray(dest) && dest[0]) {
                    try {
                        const pageIndex = await pdfDocument.getPageIndex(dest[0]);
                        const pageNumber = pageIndex + 1;
                        // gather pages +/- 1
                        pagesSet.add(pageNumber - 1);
                        pagesSet.add(pageNumber);
                        pagesSet.add(pageNumber + 1);
                    } catch (err) {
                        console.error('Error resolving page index:', err);
                    }
                }
            }
            candidatePages = [...pagesSet].filter(
                (p) => p >= 1 && p <= pdfDocument.numPages
            );
        }
    }

    // 2) If no outline hits or no outline, fallback to all pages
    if (candidatePages.length === 0) {
        candidatePages = Array.from({ length: pdfDocument.numPages }, (_, i) => i + 1);
    }

    //---------------------------
    // 3) Chunk-based Page Search
    //---------------------------
    const chunkSize = 10;
    for (let i = 0; i < candidatePages.length; i += chunkSize) {
        const chunk = candidatePages.slice(i, i + chunkSize);

        // Load all text in this chunk
        const textItemsForSearch = [];
        for (let j = 0; j < chunk.length; j++) {
            const pageNumber = chunk[j];
            try {
                const page = await pdfDocument.getPage(pageNumber);
                const textContent = await page.getTextContent();
                textContent.items.forEach((item) => {
                    textItemsForSearch.push({ pageNumber, textItem: item.str });
                });
            } catch (err) {
                console.error('Error loading text for page', pageNumber, err);
            }
        }

        // Now do a multi-term fuse search
        const fuse = new Fuse(textItemsForSearch, {
            keys: ['textItem'],
            threshold: 0.2,
            ignoreLocation: true,
            minMatchCharLength: language === 'KO' ? 3 : 7,
            includeMatches: true,
            includeScore: true,
        });

        // console.log("Searching pages...");
        // Prepare terms in priority order
        const searchTerms = [
            { term: combinedSearchTerm, type: 'combined' },
            { term: primarySearchTerm, type: 'title' },
            { term: fallbackSearchTerm, type: 'number' },
        ];

        for (const { term, type } of searchTerms) {
            if (!term) continue;
            const searchString = term.substring(0, 75);
            let results = fuse.search(searchString);
            if (type === "combine") {
                // console.log(results);
                results = results.filter((result) => result.item?.textItem?.startsWith(fallbackSearchTerm));
            } else if (type === "title") {
                // console.log(results);
                results = results.filter((result) =>
                    result.item?.textItem?.toLowerCase().includes(searchString.toLowerCase())
                );
            } else {
                // console.log(results);
                results = results.filter((result) => result.item?.textItem?.startsWith(fallbackSearchTerm));
            }
            // console.log(results)
            if (results.length > 0) {
                const firstResult = results[0];
                return {
                    pageNumber: firstResult.item.pageNumber,
                    text: firstResult.item.textItem,
                    indices: firstResult.matches[0]?.indices || [],
                    matchType: type,
                };
            }
        }
    }

    return null;
}

export async function exactPdfSearch({ pdfDocument, searchTerm }) {
    if (!pdfDocument || !searchTerm) {
        return null;
    }

    const searchTermLC = searchTerm.toLowerCase();

    // Loop through all pages of the PDF
    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
        try {
            const page = await pdfDocument.getPage(pageNumber);
            const textContent = await page.getTextContent();

            // Check each text item on the page
            for (const item of textContent.items) {
                const textStr = item.str;
                if (!textStr) continue;
                // Use a case-insensitive search
                const index = textStr.toLowerCase().indexOf(searchTermLC);
                if (index !== -1) {
                    return {
                        pageNumber,
                        text: textStr,
                        indices: [[index, index + searchTerm.length - 1]],
                        matchType: 'exact'
                    };
                }
            }
        } catch (error) {
            console.error(`Error searching page ${pageNumber}:`, error);
        }
    }

    return null;
}

export async function exactPdfSearchAll({ pdfDocument, searchTerm }) {
    if (!pdfDocument || !searchTerm) {
        return [];
    }
    const searchTermLC = searchTerm.toLowerCase();
    const results = [];

    // Loop through all pages of the PDF
    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
        try {
            const page = await pdfDocument.getPage(pageNumber);
            const textContent = await page.getTextContent();

            // Check each text item on the page for all occurrences
            for (const item of textContent.items) {
                const textStr = item.str;
                if (!textStr) continue;

                let startIndex = 0;
                while (true) {
                    const index = textStr.toLowerCase().indexOf(searchTermLC, startIndex);
                    if (index === -1) break;

                    results.push({
                        pageNumber,
                        text: textStr,
                        indices: [[index, index + searchTerm.length - 1]],
                        matchType: 'exact'
                    });

                    // Continue searching from the next character
                    startIndex = index + 1;
                }
            }
        } catch (error) {
            console.error(`Error searching page ${pageNumber}:`, error);
        }
    }
    return results;
}