import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../components/Sidebar';
import ChatWindow from '../components/ChatWindow';
import SignInPopup from '../components/SignInPopup';
import ProfileModal from '../components/ProfileModal';
import DisclaimerModal from '../components/DisclaimerModal';
import PdfViewer from '../components/PdfViewer';
import { AuthContext } from '../contexts/AuthContext';
import { useUser } from '../hooks/useUser';
import { useTopics } from '../hooks/useTopics';


const Home = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [showSignInPopup, setShowSignInPopup] = useState(false);
    const [signInMessage, setSignInMessage] = useState('');
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfDocumentName, setPdfDocumentName] = useState('');
    const [combinedSearchTerm, setCombinedSearchTerm] = useState('');
    const [primarySearchTerm, setPrimarySearchTerm] = useState('');
    const [fallbackSearchTerm, setFallbackSearchTerm] = useState('');
    const [language, setLanguage] = useState('EN');
    const [isPdfViewerVisible, setIsPdfViewerVisible] = useState(true);
    const [chatInput, setChatInput] = useState('');
    const { data: userData } = useUser(currentUser);
    const { data: topics = [] } = useTopics();

    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        if (topics.length > 0) {
            if (slug) {
                const topic = topics.find((t) => decodeURIComponent(t.slug) === slug);
                if (topic) {
                    setSelectedTopic({ ...topic, preparedQuestions: topic.prepared_questions || [] });
                } else {
                    // Slug is invalid, redirect to first topic and notify user
                    const firstTopic = topics[0];
                    setSelectedTopic({ ...firstTopic, preparedQuestions: firstTopic.prepared_questions || [] });
                    navigate(`/topics/${firstTopic.slug}`, { replace: true });
                    toast.error('Topic not found. Redirected to the first topic.');
                }
            } else {
                // If no slug in URL, select the first topic by default
                const firstTopic = topics[0];
                setSelectedTopic({ ...firstTopic, preparedQuestions: firstTopic.prepared_questions || [] });
                navigate(`/topics/${firstTopic.slug}`, { replace: true });
            }
        }
    }, [topics, slug, navigate]);

    useEffect(() => {
        if (currentUser) {
            const hasSeenDisclaimer = localStorage.getItem(`hasSeenDisclaimer_${currentUser.uid}`);
            if (!hasSeenDisclaimer) {
                setShowDisclaimerModal(true);
            }
        }
    }, [currentUser]);

    // Unified function to sanitize document names for both English and Korean files
    const sanitizeDocumentName = (name, lang) => {
        // Remove .pdf extension if present
        const baseName = name.endsWith('.pdf') ? name.slice(0, -4) : name;

        // Check if the name contains non-ASCII characters (e.g. Korean characters)
        if (lang === "KO") {
            // For non-ASCII (Korean) file names, normalize and percent-encode
            const normalizedName = baseName.normalize('NFD');
            return encodeURIComponent(normalizedName);
        } else {
            // For English file names, remove leading/trailing non-letter characters,
            // replace spaces and underscores with '+', and convert to uppercase
            return baseName
                .replace(/^[^A-Za-z]+|[^A-Za-z]+$/g, '')
                .replace(/[\s_]+/g, '+')
                .toUpperCase();
        }
    };

    const handleReferenceClick = (documentName, articleNumber, articleTitle) => {
        const lang = articleNumber.startsWith("Article") ? "EN" : "KO";
        const baseUrl = process.env.REACT_APP_PDF_BASE_URL;
        const sanitizedDocumentName = sanitizeDocumentName(documentName, lang);

        const pdfUrl = `${baseUrl}/${sanitizedDocumentName}.pdf`;
        setPdfUrl(pdfUrl);
        setPdfDocumentName(documentName);

        setLanguage(lang);
        const combinedTerm = lang === "KO"
            ? `${articleNumber}(${articleTitle}`
            : `${articleNumber} (${articleTitle})`;
        setCombinedSearchTerm(combinedTerm);
        setPrimarySearchTerm(articleTitle);
        setFallbackSearchTerm(`${articleNumber}`);

        setIsPdfViewerVisible(true);
    };

    const handleCloseDisclaimerModal = () => {
        setShowDisclaimerModal(false);
        if (currentUser) {
            localStorage.setItem(`hasSeenDisclaimer_${currentUser.uid}`, 'true');
        }
    };

    const handleSelectTopic = (topic) => {
        setSelectedTopic(topic);
        setIsSidebarOpen(false);
        navigate(`/topics/${topic.slug}`);
    };

    const handleShowSignInPopup = (shouldShow, message = 'Please sign in to continue.') => {
        if (shouldShow) {
            setSignInMessage(message);
        }
        setShowSignInPopup(shouldShow);
    };

    const handleShowProfileModal = (shouldShow) => {
        setShowProfileModal(shouldShow);
    };

    const togglePdfViewer = () => {
        setIsPdfViewerVisible((prev) => !prev);
    };

    const handleQuoteSelected = (quotedText) => {
        const prefix = language === "KO" ? "설명" : "Explain";
        setChatInput(`${prefix}: "${quotedText}"`);
    };

    return (
        <div className="flex h-screen">
            <DisclaimerModal isOpen={showDisclaimerModal} onClose={handleCloseDisclaimerModal} />
            <ToastContainer />
            {/* Hamburger button, visible on small screens */}
            {!isSidebarOpen && (
                <header className="fixed top-0 left-0 z-50 flex items-center w-full p-4 bg-white md:hidden">
                    <button
                        aria-label="Open sidebar"
                        onClick={() => setIsSidebarOpen(true)}
                        className="mr-4"
                    >
                        <svg className="w-6 h-6" viewBox="0 0 24 24">
                            <path
                                d="M4 6h16M4 12h16M4 18h16"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    </button>
                    <h1 className="text-xl font-bold text-gray-800">Lexcha</h1>
                </header>
            )}

            {/* Sidebar */}
            <Sidebar
                isOpen={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
                onSelectTopic={handleSelectTopic}
                selectedTopic={selectedTopic}
                onSignIn={(show, message) => handleShowSignInPopup(show, message)}
                onProfileClick={() => handleShowProfileModal(true)}
            />

            {/* Main content area */}
            <div className="flex flex-1">
                {!isMobile ? (
                    <>
                        {/* Desktop layout: show both ChatWindow and PdfViewer side by side */}
                        <ChatWindow
                            selectedTopic={selectedTopic}
                            onReferenceClick={handleReferenceClick}
                            selectedText={chatInput}
                        />
                        {pdfUrl && (
                            <PdfViewer
                                pdfUrl={pdfUrl}
                                combinedSearchTerm={combinedSearchTerm}
                                primarySearchTerm={primarySearchTerm}
                                fallbackSearchTerm={fallbackSearchTerm}
                                isPdfViewerVisible={isPdfViewerVisible}
                                togglePdfViewer={togglePdfViewer}
                                pdfDocumentName={pdfDocumentName}
                                onQuoteSelected={handleQuoteSelected}
                                language={language}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {/* Mobile layout */}
                        {pdfUrl && isPdfViewerVisible ? (
                            <PdfViewer
                                pdfUrl={pdfUrl}
                                combinedSearchTerm={combinedSearchTerm}
                                primarySearchTerm={primarySearchTerm}
                                fallbackSearchTerm={fallbackSearchTerm}
                                isPdfViewerVisible={isPdfViewerVisible}
                                togglePdfViewer={togglePdfViewer}
                                pdfDocumentName={pdfDocumentName}
                                onQuoteSelected={handleQuoteSelected}
                                language={language}
                            />
                        ) : (
                            <ChatWindow
                                selectedTopic={selectedTopic}
                                onReferenceClick={handleReferenceClick}
                                selectedText={chatInput}
                            />
                        )}
                    </>
                )}
            </div>

            {showSignInPopup && (
                <SignInPopup
                    onClose={() => handleShowSignInPopup(false)}
                    message={signInMessage}
                />
            )}
            {showProfileModal && (
                <ProfileModal
                    user={userData}
                    googleUser={currentUser}
                    onClose={() => handleShowProfileModal(false)}
                />
            )}
        </div>
    );
};

export default Home;