import React, { useEffect, useState, useRef } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useMediaQuery } from 'react-responsive';
import { FaTimes, FaChevronLeft, FaChevronRight, FaSearch } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FixedSizeList as List } from 'react-window';
import { pdfSearch, exactPdfSearchAll } from '../utils/pdfSearch';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

function PdfViewer({
    pdfUrl,
    combinedSearchTerm,
    primarySearchTerm,
    fallbackSearchTerm,
    togglePdfViewer,
    pdfDocumentName,
    isPdfViewerVisible,
    onQuoteSelected,
    language
}) {
    const [numPages, setNumPages] = useState(null);
    const [pdfDocument, setPdfDocument] = useState(null);
    const [outline, setOutline] = useState([]);
    const [matchedItem, setMatchedItem] = useState(null);
    const containerRef = useRef(null);
    const listRef = useRef(null);
    const [searchTrigger, setSearchTrigger] = useState(0);
    const quoteButtonRef = useRef(null);
    const searchInputRef = useRef(null);
    const [exactSearchResults, setExactSearchResults] = useState([]);
    const [currentExactSearchIndex, setCurrentExactSearchIndex] = useState(0);
    const [lastExactSearchTerm, setLastExactSearchTerm] = useState('');

    const [containerWidth, setContainerWidth] = useState(null);
    const [containerHeight, setContainerHeight] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    // Update container dimensions on resize
    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
                setContainerHeight(containerRef.current.offsetHeight);
            }
        };

        updateDimensions(); // Initial dimensions
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        const handleSelection = (e) => {
            const selection = window.getSelection();
            const selectedText = selection ? selection.toString().trim() : '';

            if (selectedText.length > 0) {
                // Get the bounding rectangle of the selected range.
                const range = selection.getRangeAt(0);
                const rect = range.getBoundingClientRect();

                // Position the quote button near the selection.
                if (quoteButtonRef.current) {
                    quoteButtonRef.current.style.top = `${rect.top - 40 + window.scrollY}px`;
                    quoteButtonRef.current.style.left = `${rect.left + window.scrollX}px`;
                    quoteButtonRef.current.style.display = 'block';
                }
            } else {
                if (quoteButtonRef.current) {
                    quoteButtonRef.current.style.display = 'none';
                }
            }
        };

        if (!isMobile) {
            document.addEventListener('mouseup', handleSelection);
        }

        return () => {
            if (!isMobile) {
                document.removeEventListener('mouseup', handleSelection);
            }
        };
    }, []);

    const handleExactSearch = async () => {
        const searchTerm = searchInputRef.current.value.trim();
        if (!pdfDocument || !searchTerm) return;
    
        // If the search term changed, run a fresh search
        if (searchTerm !== lastExactSearchTerm) {
            const results = await exactPdfSearchAll({ pdfDocument, searchTerm });
            if (results.length === 0) {
                setExactSearchResults([]);
                setCurrentExactSearchIndex(0);
                setLastExactSearchTerm(searchTerm);
                setMatchedItem(null);
                toast.error('Exact phrase not found.');
                return;
            }
            setExactSearchResults(results);
            setCurrentExactSearchIndex(0);
            setLastExactSearchTerm(searchTerm);
            setMatchedItem(results[0]);
            scrollToMatchedPage(results[0].pageNumber);
        } else {
            // Same search term: cycle to the next occurrence
            if (exactSearchResults.length === 0) {
                toast.error('Exact phrase not found.');
                return;
            }
            const nextIndex = (currentExactSearchIndex + 1) % exactSearchResults.length;
            setCurrentExactSearchIndex(nextIndex);
            setMatchedItem(exactSearchResults[nextIndex]);
            scrollToMatchedPage(exactSearchResults[nextIndex].pageNumber);
        }
    };

    // Handler for the Quote button click
    const handleQuoteClick = () => {
        const selection = window.getSelection();
        const selectedText = selection ? selection.toString().trim() : '';
        if (selectedText.length > 0) {
            // Call the callback from Home to update ChatWindow
            onQuoteSelected(selectedText);
            if (isMobile) {
                togglePdfViewer();
            }
            // Optionally, clear the selection and hide the button
            if (window.getSelection) {
                window.getSelection().removeAllRanges();
            }
            if (quoteButtonRef.current) {
                quoteButtonRef.current.style.display = 'none';
            }
        }
    };

    useEffect(() => {
        setPdfDocument(null);
    }, [pdfUrl]);

    // Handle successful document load
    function onDocumentLoadSuccess(pdf) {
        const flattenOutline = (outline, parent = null) => {
            let flatOutline = [];
            outline.forEach((entry) => {
                const { title, dest, items } = entry;
                flatOutline.push({ title, dest, parent });
                if (items && items.length > 0) {
                    flatOutline = flatOutline.concat(flattenOutline(items, entry));
                }
            });
            return flatOutline;
        };

        pdf.getOutline().then((outlineData) => {
            if (outlineData) {
                const flattenedOutline = flattenOutline(outlineData);
                setOutline(flattenedOutline);
            } else {
                setOutline([]);
            }
            setNumPages(pdf.numPages);
            setPdfDocument(pdf);
        }).catch((error) => {
            console.error("Error fetching outline:", error);
        });
    }

    /**
     * Kick off the search whenever search terms update, and PDF doc + outline are loaded.
     */
    useEffect(() => {
        if (pdfDocument && (combinedSearchTerm || primarySearchTerm || fallbackSearchTerm)) {
            setSearchTrigger((prev) => prev + 1);
        }
    }, [combinedSearchTerm, primarySearchTerm, fallbackSearchTerm, pdfDocument]);

    /**
     * Main search effect. Decides which pages to load and search in chunks.
     */
    useEffect(() => {
        if (!pdfDocument || (!combinedSearchTerm && !primarySearchTerm && !fallbackSearchTerm)) {
            setMatchedItem(null);
            return;
        }

        const doSearch = async () => {
            try {
                const result = await pdfSearch({
                    pdfDocument,
                    outline,
                    combinedSearchTerm,
                    primarySearchTerm,
                    fallbackSearchTerm,
                    language
                });

                if (result) {
                    setMatchedItem(result);
                    scrollToMatchedPage(result.pageNumber);
                } else {
                    setMatchedItem(null);
                    toast.error('Article not found.');
                }
            } catch (error) {
                console.error('Error during search:', error);
                toast.error('An error occurred during the search.');
            }
        };

        doSearch();
    }, [searchTrigger]);

    function scrollToMatchedPage(pageNumber) {
        if (listRef.current) {
            listRef.current.scrollToItem(pageNumber - 1, 'center');
        }
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\-]/g, '\\$&');
    }

    function highlightPattern(text, pattern) {
        if (!pattern) return text;

        const escapedPattern = escapeRegExp(pattern);
        const regex = new RegExp(`(${escapedPattern})`, 'g');
        return text.replace(regex, (match) => `<mark>${match}</mark>`);
    }

    // Custom text renderer to highlight only the matched text item
    function customTextRenderer(textItem) {
        if (!matchedItem) return textItem.str;
        const { pageNumber, text, indices } = matchedItem;
        // Check if the current textItem matches the stored matchedItem
        if (pageNumber !== textItem.pageNumber || text !== textItem.str) {
            return textItem.str;
        }
        // Attempt simple RegEx Match Highlighting with combined search term first
        const highlightedRegEx = highlightPattern(textItem.str, combinedSearchTerm);
        if (highlightedRegEx !== textItem.str) {
            return highlightedRegEx;
        }

        if (!indices || indices.length === 0) {
            return textItem.str;
        }

        // Highlight only the first match
        const [start, end] = indices[0];
        const beforeMatch = textItem.str.slice(0, start);
        const matchedText = textItem.str.slice(start, end + 1); // end is inclusive
        const afterMatch = textItem.str.slice(end + 1);

        return `${beforeMatch}<mark>${matchedText}</mark>${afterMatch}`;
    }

    // Determine the width based on screen size and visibility
    const getWidth = () => {
        if (isMobile) {
            return isPdfViewerVisible ? '100vw' : '0';
        }
        return isPdfViewerVisible ? '50%' : '50px';
    };

    // Determine the minWidth based on screen size
    const getMinWidth = () => {
        if (isMobile) {
            return '0';
        }
        return '50px';
    };

    // Determine whether to display the PDF content
    const shouldDisplayPdfContent = () => {
        if (isMobile) {
            return isPdfViewerVisible;
        }
        return true; // On desktop, always render the content container
    };

    // Define the aspect ratio for A4 (width : height = 1 : 1.414)
    const ASPECT_RATIO = 1 / 1.414;

    // Calculate itemSize based on container width and aspect ratio, adding a 10px gap
    const itemHeight = containerWidth ? containerWidth / ASPECT_RATIO + 10 : 800; // Fallback to 800px if width is not available

    // Row component for react-window
    const Row = ({ index, style }) => {
        const pageNumber = index + 1;
        return (
            <div style={{ ...style, paddingBottom: '10px' }} className={`page-container-${pageNumber}`}>
                <Page
                    pageNumber={pageNumber}
                    renderTextLayer={true}
                    renderAnnotationLayer={true}
                    customTextRenderer={customTextRenderer}
                    width={containerWidth}
                    loading={null}
                />
            </div>
        );
    };

    return (
        <div
            className="bg-gray-100 flex flex-col transition-all duration-300 overflow-hidden"
            style={{
                width: getWidth(),
                minWidth: getMinWidth(),
            }}
        >
            <ToastContainer />
            {/* Header with Document Name and Toggle Button */}
            <div className="flex items-center p-2 bg-gray-200 flex-shrink-0">
                <h2 className="flex-1 text-lg font-semibold truncate">{pdfDocumentName}</h2>
                {isPdfViewerVisible && (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleExactSearch();
                        }}
                    >
                        <div className="relative mr-2">
                            <FaSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500" />
                            <input
                                type="text"
                                placeholder="Exact phrase search"
                                ref={searchInputRef}
                                className="pl-8 pr-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                            />
                        </div>
                    </form>
                )}
                <ToggleButton
                    isMobile={isMobile}
                    isPdfViewerVisible={isPdfViewerVisible}
                    togglePdfViewer={togglePdfViewer}
                />
            </div>

            {/* PDF Content */}
            {shouldDisplayPdfContent() && (
                <div
                    className={`flex-1 overflow-hidden transition-opacity duration-300 ${isPdfViewerVisible ? 'opacity-100' : 'opacity-0'
                        }`}
                >
                    <div
                        ref={containerRef}
                        style={{ height: '100%', width: '100%', position: 'relative' }}
                    >
                        <Document key={pdfUrl} file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                            {numPages && (
                                <List
                                    ref={listRef}
                                    height={containerHeight || 800}
                                    itemCount={numPages}
                                    itemSize={itemHeight}
                                    width={containerWidth || '100%'}
                                >
                                    {Row}
                                </List>
                            )}
                        </Document>
                    </div>
                </div>
            )}
            {/* Floating Quote Button */}
            <button
                ref={quoteButtonRef}
                onClick={handleQuoteClick}
                style={{
                    position: 'absolute',
                    display: 'none', // initially hidden
                    zIndex: 1000,
                    padding: '6px 12px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
            >
                Quote
            </button>
        </div>
    );
}

function ToggleButton({ isMobile, isPdfViewerVisible, togglePdfViewer }) {
    // Determine which icon to display based on viewport and visibility
    const renderIcon = () => {
        if (isMobile) {
            return <FaTimes size={20} />;
        }
        return isPdfViewerVisible ? <FaChevronRight /> : <FaChevronLeft />;
    };

    // Determine the button's ARIA label
    const getAriaLabel = () => {
        if (isMobile) {
            return 'Close PDF Viewer';
        }
        return isPdfViewerVisible ? 'Hide PDF Viewer' : 'Show PDF Viewer';
    };

    // Determine the button's className based on viewport
    const getButtonClass = () => {
        if (isMobile) {
            return 'bg-gray-800 text-white p-3 rounded-full shadow-lg fixed bottom-4 right-4 z-50';
        }
        return 'p-2 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400';
    };

    return (
        <button
            onClick={togglePdfViewer}
            aria-label={getAriaLabel()}
            className={getButtonClass()}
            style={isMobile ? {} : {}}
        >
            {renderIcon()}
        </button>
    );
}

export default PdfViewer;
