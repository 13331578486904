import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaTelegramPlane, FaEnvelope, FaLock } from 'react-icons/fa';
import { AuthContext } from '../contexts/AuthContext';
import { signOut } from '../services/authService';
import { useTopics } from '../hooks/useTopics';
import UserMenu from './UserMenu';

const Sidebar = ({ isOpen, onClose, onSelectTopic, selectedTopic, onSignIn, onProfileClick }) => {
    const { data: topics = [], isLoading: topicsLoading, isError: topicsError } = useTopics();
    const { currentUser } = useContext(AuthContext);

    const isUserAnonymous = currentUser && currentUser.isAnonymous;

    const handleTopicClick = (topic) => {
        onSelectTopic(topic);
        onClose(); // Close sidebar when a topic is selected
    };

    const handleAuthAction = async () => {
        onSignIn(true);
        onClose(); // Close sidebar when sign-in popup is shown
    };

    const handleSignOut = async () => {
        await signOut();
        onClose(); // Close sidebar when user signs out
    }

    const handleProfileClick = () => {
        onProfileClick();
        onClose(); // Close sidebar when profile modal is shown
    };

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleTopicItemClick = (topic, index) => {
        const isLocked = index > 2;

        if (isLocked && (!currentUser || isUserAnonymous)) {
            // If the topic is locked and the user is anonymous, prompt sign-in
            onSignIn(true, "Sign in to unlock extra topics.");
        } else {
            // Otherwise, proceed to handle the topic selection
            handleTopicClick(topic);
        }
    };

    const SidebarContent = () => {
        return (
            <div className="flex flex-col justify-between h-full">
                <div>
                    <h1 className="text-3xl font-extrabold text-blue-600 mb-2 tracking-wide">
                        <span className="text-gray-700">Lexcha</span>
                    </h1>
                    <p className="text-gray-600 mb-8">AI-Powered Korean Law Navigator</p>
                    {topicsLoading ? (
                        <p>Loading topics...</p>
                    ) : topicsError ? (
                        <p className="text-red-500">Failed to load topics.</p>
                    ) : (
                        <ul>
                            {topics.map((topic, index) => {
                                const locked = index > 2 && (!currentUser || isUserAnonymous);
                                return (
                                    <li
                                        key={topic.id}
                                        onClick={() => handleTopicItemClick(topic, index)}
                                        className={`flex items-center cursor-pointer p-3 mb-2 rounded-md ${selectedTopic && selectedTopic.id === topic.id
                                                ? 'bg-blue-500 text-white font-semibold'
                                                : 'bg-gray-100 hover:bg-gray-300 text-gray-700'
                                            }`}
                                    >
                                        {locked && (
                                            <FaLock className="mr-2 text-gray-500" aria-label="Locked" />
                                        )}
                                        <span>{topic.name}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
                <div className="mt-8">
                    {/* Footer Section */}
                    <div className="border-t border-gray-300 pt-4">
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/terms-of-service"
                                    className="text-gray-700 hover:text-blue-500"
                                    onClick={onClose} // Close sidebar on link click
                                >
                                    Terms of Service
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/privacy-policy"
                                    className="text-gray-700 hover:text-blue-500"
                                    onClick={onClose} // Close sidebar on link click
                                >
                                    Privacy Policy
                                </Link>
                            </li>
                        </ul>
                        <div className="mt-4 flex space-x-4">
                            <a
                                href="https://t.me/lexcha"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-700 hover:text-blue-500"
                                aria-label="Telegram"
                            >
                                <FaTelegramPlane size={20} />
                            </a>
                            <a
                                href="mailto:support@lexcha.xyz"
                                className="text-gray-700 hover:text-blue-500"
                                aria-label="Email"
                            >
                                <FaEnvelope size={20} />
                            </a>
                        </div>
                    </div>
                    {/* Authentication Section */}
                    <div className="flex mt-12 mb-2">
                        {currentUser && !currentUser.isAnonymous ? (
                            <UserMenu
                                currentUser={currentUser}
                                onProfileClick={handleProfileClick}
                                onSignOut={handleSignOut}
                                onItemClick={onClose} // Close the sidebar when an item is clicked
                            />
                        ) : (
                            <button
                                onClick={handleAuthAction}
                                className="flex items-center bg-white border border-gray-300 rounded-md p-1 hover:bg-gray-100"
                            >
                                <img
                                    src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png"
                                    alt="Google Sign-In"
                                    className="w-full"
                                />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {/* Mobile Sidebar */}
            {isOpen && (
                <div
                    className="fixed inset-0 z-40 flex"
                    onClick={handleOutsideClick}
                >
                    <div
                        className="w-1/8 bg-gray-200 p-4 max-h-screen overflow-y-auto"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <SidebarContent />
                    </div>
                </div>
            )}

            {/* Desktop Sidebar */}
            <div className="hidden md:block w-1/8 bg-gray-200 p-4">
                <SidebarContent />
            </div>
        </>
    );
};

export default Sidebar;
